import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ToggleButton from "./ui/ToggleButton.tsx";
import Logo from "../images/logo.svg";
import { Button } from "./ui/button.tsx";
import { ContextApp } from "../utils/Context.jsx";
import { ThemeContext } from "./ThemeContext";
import { FiMenu, FiX } from "react-icons/fi";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsGuest, isSidebarCollapsed } = useContext(ContextApp);
  const { isDarkMode } = useContext(ThemeContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleGuestLogin = () => {
    setIsGuest(true);
    navigate("/guest-chat");
    setIsMenuOpen(false);
  };

  const handleLogin = () => {
    navigate("/login");
    setIsMenuOpen(false);
  };

  return (
    <header
      className={`relative flex justify-between items-center px-8 py-6 text-primary 
        ${
          isDarkMode ? "bg-transparent dark:bg-transparent" : "bg-transparent"
        }`}
    >
      <div className="flex items-center">
        <img src={Logo} alt="Logo" className="h-16 w-16" />
      </div>

      <div
        className={`hidden md:flex items-center gap-4 transition-all duration-300 ${
          isSidebarCollapsed ? "mr-16" : "mr-[320px]"
        }`}
      >
        <ToggleButton />
        {location.pathname !== "/login" && (
          <>
            <Button variant="secondary" onClick={handleGuestLogin}>
              Invitado
            </Button>
            <Button variant="primary" onClick={handleLogin}>
              Iniciar Sesión
            </Button>
          </>
        )}
      </div>
      <div className="md:hidden">
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Abrir menú"
          className="text-3xl focus:outline-none relative right-24"
        >
          {isMenuOpen ? <FiX /> : <FiMenu />}
        </button>
      </div>
      {isMenuOpen && (
        <div
          className={`absolute top-full left-0 right-0 
            ${isDarkMode ? "bg-neutral-950" : "bg-neutral-100"} 
            shadow-2xl p-6 flex flex-col items-center gap-4 md:hidden z-50 rounded-lg w-60 place-self-center border-green-500 border`}
        >
          <ToggleButton />
          {location.pathname !== "/login" && (
            <>
              <Button variant="secondary" onClick={handleGuestLogin}>
                Invitado
              </Button>
              <Button variant="primary" onClick={handleLogin}>
                Iniciar Sesión
              </Button>
            </>
          )}
        </div>
      )}
    </header>
  );
};

export default Header;
