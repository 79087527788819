import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "./ThemeContext";

function Footer() {
  const [footerText, setFooterText] = useState("");
  const { isDarkMode } = useContext(ThemeContext);

  useEffect(() => {
    async function fetchFooterData() {
      const data = await getConfigData();
      setFooterText(data.FOOTER);
    }

    fetchFooterData();
  }, []);

  async function getConfigData() {
    const response = await fetch("/configuration.json");
    const data = await response.json();
    return data;
  }

  return (
    <footer
      className={`w-full text-center py-2 fixed bottom-4 right-4 sm:right-4 text-xs sm:text-base ${
        isDarkMode ? "text-white" : "text-black"
      }`}
    >
      {footerText}
    </footer>
  );
}

export default Footer;
