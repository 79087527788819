import React, { useContext, useEffect, useRef, useState } from "react";
import { ContextApp } from "../utils/Context";
import iconBot from "../images/botIcon.svg";
import iconUser from "../images/userIcon.svg";
import { Spin } from "antd";
import { ThemeContext } from "../components/ThemeContext";

function Chat() {
  const { isDarkMode } = useContext(ThemeContext);
  const {
    message,
    showSlide,
    currentTopic,
    loadChatHistoryForTopic,
    isAwaitingNewResponse,
    setIsAwaitingNewResponse,
  } = useContext(ContextApp);

  const endOfMessagesRef = useRef(null);
  const [shouldScrollToBottom] = useState(true);
  const messagesContainerRef = useRef(null);

  const chatClass = `
    w-full 
    h-[85%] 
    overflow-hidden 
    overflow-y-scroll 
    mt-24 
    ${showSlide ? "pl-12" : "pl-2"} 
    pr-2
    sm:pl-4 
    sm:pr-4
    lg:pl-8 
    lg:pr-8
  `;

  useEffect(() => {
    if (shouldScrollToBottom) {
      endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [message, shouldScrollToBottom]);

  return (
    <div ref={messagesContainerRef} className={chatClass}>
      {message && message.length > 0 ? (
        <div className="flex flex-col w-full justify-end items-center pb-4 px-2 sm:px-4 lg:px-8">
          {message.map((msg, i) => (
            <div
              key={i}
              className={`flex mb-4 w-full max-w-[90%] sm:max-w-[700px] relative right-6 sm:right-0 ${
                msg.isBot ? "justify-start" : "justify-end"
              }`}
            >
              {msg.isBot && (
                <div className="w-8 h-8 flex items-center justify-center mr-1 sm:mr-2">
                  <img
                    src={iconBot}
                    alt="Bot"
                    className="w-6 h-6 sm:w-8 sm:h-8 object-cover"
                  />
                </div>
              )}
              <div
                className={`p-2 sm:p-3 shadow-md semibold rounded-lg max-w-[70%] sm:max-w-[60%] ${
                  msg.isBot
                    ? "bg-neutral-900 text-white"
                    : "bg-blue-100 text-black"
                }`}
              >
                <p className="whitespace-pre-wrap text-xs sm:text-sm">
                  {typeof msg.text === "string" &&
                  msg.isBot &&
                  msg.text.startsWith(" ")
                    ? msg.text.slice(1)
                    : msg.text}
                </p>
              </div>

              {!msg.isBot && (
                <div className="w-8 h-8 flex items-center justify-center ml-1 sm:ml-2">
                  <img
                    src={iconUser}
                    alt="User"
                    className="w-6 h-6 sm:w-8 sm:h-8 object-cover"
                  />
                </div>
              )}
            </div>
          ))}
          {isAwaitingNewResponse && (
            <div className="flex flex-col justify-center items-center h-full space-y-4">
              <Spin />
              <p className="relative font-semibold text-gray-300 animate-shine text-lg mt-6 sm:text-2xl">
                {"Buscando información..."}
              </p>
            </div>
          )}

          <div ref={endOfMessagesRef} />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-full w-full">
          <p
            className={`font-bold text-center px-4 text-xl sm:text-3xl md:text-5xl md:bottom-60 lg:text-5xl lg:bottom-64 relative right-8 bottom-56 ${
              isDarkMode ? "text-white" : "text-black"
            }`}
          >
            {"¿Cómo puedo ayudarte hoy?"}
          </p>
        </div>
      )}
    </div>
  );
}

export default Chat;
