import React from "react";

export const Button = ({
  children,
  className = "",
  variant = "primary",
  size = "md",
  ...props
}) => {
  const baseStyles =
    "inline-flex items-center justify-center font-medium rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed";

  const variants = {
    primary:
      "bg-green-500 text-white border border-white shadow-lg hover:bg-green-600 focus:ring-green-400",
    secondary:
      "bg-zinc-900 text-white border border-white shadow-lg hover:bg-zinc-600 focus:ring-gray-500",
    outline:
      "bg-gray-700 border border-zinc-700 text-white hover:bg-zinc-800 focus:ring-zinc-500",
  };

  const sizes = {
    sm: "text-sm px-3 py-2",
    md: "text-base px-4 py-2",
    lg: "text-lg px-6 py-3",
  };

  const classes = `${baseStyles} ${variants[variant]} ${sizes[size]} ${className}`;

  return (
    <button className={classes} {...props}>
      {children}
    </button>
  );
};
