import React, { useContext, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { LuPanelLeftClose } from "react-icons/lu";
import { ContextApp } from "../utils/Context.jsx";
import { ThemeContext } from "../components/ThemeContext";
import NewChatButton from "./ui/NewChatButton.tsx";

const RightSidebar = () => {
  const {
    resetChat,
    topics = [],
    loadChatHistoryForTopic,
    deleteTopicFromList,
  } = useContext(ContextApp);
  const { isDarkMode } = useContext(ThemeContext);
  const { setIsSidebarCollapsed } = useContext(ContextApp);

  const truncateText = (text, maxLength) =>
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    setIsSidebarCollapsed(!isCollapsed);
  };

  return (
    <div
      className={`fixed top-0 right-0 h-full border-l z-50 shadow-lg transition-all duration-300 ${
        isCollapsed ? "w-[60px]" : "w-[300px]"
      } ${
        isDarkMode
          ? "bg-neutral-800 text-white border-neutral-700"
          : "bg-gray-200 text-black"
      }`}
    >
      <div className="p-4 mt-5 flex justify-start">
        <button
          className={`${
            isDarkMode
              ? "text-gray-400 hover:text-white"
              : "text-gray-600 hover:text-black"
          }`}
          onClick={handleCollapse}
        >
          <LuPanelLeftClose size={38} />
        </button>
      </div>

      {!isCollapsed && (
        <>
          <div className="p-4 flex justify-between items-center">
            <h2 className="text-xl font-bold mt-8 text-green-500">Historial</h2>
          </div>

          <div className="flex-1 overflow-y-auto p-4">
            {topics && topics.length > 0 ? (
              topics.map((topic, index) => (
                <div
                  key={index}
                  className={`flex justify-between items-center p-2 rounded-md mb-2 cursor-pointer ${
                    isDarkMode
                      ? "bg-gray-800 hover:bg-gray-700"
                      : "bg-gray-100 hover:bg-gray-200"
                  }`}
                  onClick={() => loadChatHistoryForTopic(topic.id)}
                >
                  <span className="truncate">
                    {truncateText(topic.name, 20)}
                  </span>
                  <AiFillDelete
                    className={`${
                      isDarkMode
                        ? "text-red-500 hover:text-red-700"
                        : "text-red-400 hover:text-red-600"
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteTopicFromList(topic.id);
                    }}
                  />
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500">
                No hay temas disponibles
              </p>
            )}
          </div>

          <div
            className={`p-4 border-t ${
              isDarkMode ? "border-gray-700" : "border-gray-300"
            }`}
          >
            <h2 className="text-lg font-bold mb-2">Seleccionar colección</h2>
            <div
              className={`p-2 rounded-md mb-2 cursor-pointer ${
                isDarkMode ? "bg-gray-800" : "bg-gray-100"
              }`}
            >
              Colección1
            </div>
            <div
              className={`p-2 rounded-md mb-2 cursor-pointer ${
                isDarkMode ? "bg-gray-800" : "bg-gray-100"
              }`}
            >
              Colección2
            </div>
            <div
              className={`p-2 rounded-md cursor-pointer ${
                isDarkMode ? "bg-gray-800" : "bg-gray-100"
              }`}
            >
              Colección3
            </div>
          </div>

          <div className="p-4">
            <NewChatButton onClick={resetChat} />
          </div>
        </>
      )}
    </div>
  );
};

export default RightSidebar;
