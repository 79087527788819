import React from "react";

interface NewChatButtonProps {
  onClick: () => void;
}

const NewChatButton: React.FC<NewChatButtonProps> = ({ onClick }) => {
  return (
    <button
      className="flex items-center w-full py-2 justify-center shadow-xl bg-neutral-900 border-2 border-green-500 rounded-full text-green-500 hover:bg-green-500 hover:text-black transition-colors"
      onClick={onClick}
    >
      <span className="mr-2 text-lg font-bold">+</span> Nuevo chat
    </button>
  );
};

export default NewChatButton;
