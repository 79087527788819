import React, { InputHTMLAttributes } from "react";
import { useTheme } from "../ThemeContext";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

export const Input: React.FC<InputProps> = ({ className = "", ...props }) => {
  const { isDarkMode } = useTheme();

  return (
    <input
      className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:border-transparent ${
        isDarkMode
          ? "bg-zinc-800 border-zinc-700 text-white placeholder-zinc-400 focus:ring-green-500"
          : "bg-white border-gray-300 text-black placeholder-gray-400 focus:ring-green-500"
      } ${className}`}
      {...props}
    />
  );
};
