import React from "react";
import RightSidebar from "../components/RightSidebar";
import ChatContainer from "../components/ChatContainer";
import Header from "../components/Header";
import AppContext from "../utils/Context";

function Home() {
  return (
    <AppContext>
      <div className="flex flex-col h-screen">
        <Header />
        <div className="flex flex-1 overflow-hidden">
          <ChatContainer />
          <RightSidebar />
        </div>
      </div>
    </AppContext>
  );
}

export default Home;
