import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { changePassword, login } from "../../services/loginApi.jsx";
import { toast } from "react-toastify";
import { ContextApp } from "../../utils/Context";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Button } from "../ui/button.tsx";
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card.tsx";
import { Input } from "../ui/input.tsx";
import { Info } from "lucide-react";
import { useTheme } from "../ThemeContext";

function Login() {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [needPasswordChange, setNeedPasswordChange] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { setIsLoggedIn, setIsGuest } = useContext(ContextApp);
  const [session, setSession] = useState("");
  const [textConfig, setTextConfig] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchTextConfig = async () => {
    try {
      const response = await fetch("/text.json");
      const data = await response.json();
      setTextConfig(data);
    } catch (error) {
      console.error("Error loading text config:", error);
    }
  };

  useEffect(() => {
    fetchTextConfig();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleGuestLogin = () => {
    setIsLoggedIn(false);
    setIsGuest(true);
    navigate("/guest-chat");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();
    toast.info(textConfig.LOGIN_TRYING_LOGIN);
    try {
      const response = await login(user, password);
      toast.dismiss();
      if (response.ChallengeName === "NEW_PASSWORD_REQUIRED") {
        setNeedPasswordChange(true);
        setSession(response.Session);
        toast.success(textConfig.LOGIN_NEED_CHANGE_PASSWORD);
        setErrorMessage(textConfig.LOGIN_NEED_CHANGE_PASSWORD);
      } else {
        toast.success(textConfig.LOGIN_TOAST_LOGIN_SUCCESS);
        setIsLoggedIn(true);
        setIsGuest(false);
        navigate("/");
      }
    } catch (error) {
      console.error(error);
      toast.dismiss();
      setErrorMessage(textConfig.LOGIN_TOAST_LOGIN_FAIL);
      toast.error(textConfig.LOGIN_TOAST_LOGIN_FAIL, { autoClose: 5000 });
    }
  };

  return (
    <div className="flex flex-col lg:flex-row items-center justify-center p-4 lg:p-20">
      <div
        className={`w-full lg:w-1/2 space-y-4 lg:pr-8 mb-8 lg:mb-0 ${
          isDarkMode ? "text-white" : "text-black"
        }`}
      >
        <h1
          className={`text-3xl lg:text-5xl font-bold ${
            isDarkMode ? "text-white" : "text-black"
          }`}
        >
          Bienvenido a Go! Support & Assist
        </h1>
        <h2 className={`text-xl lg:text-3xl pt-8 text-green-500`}>
          Tu Asistente Virtual Empresarial
        </h2>
        <p
          className={`text-lg font-semibold ${
            isDarkMode ? "text-gray-400" : "text-gray-700"
          }`}
        >
          Accede a la plataforma y recibe ayuda en tiempo real de nuestro equipo
          de expertos. ¿Listo para comenzar?
        </p>
      </div>

      <div className="w-full lg:w-1/2 max-w-md">
        <Card>
          <CardHeader>
            <CardTitle>Ingresar</CardTitle>
            <p
              className={`text-sm text-center ${
                isDarkMode ? "text-gray-300" : "text-gray-500"
              }`}
            >
              Ingresa a tu cuenta para acceder a todas las funcionalidades de
              soporte en tiempo real.
            </p>
          </CardHeader>
          <CardContent className="space-y-4">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-4">
                <Input
                  type="text"
                  placeholder="Usuario"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                />
                <div
                  className={`text-xs text-center ${
                    isDarkMode ? "text-gray-500" : "text-gray-700"
                  }`}
                >
                  Ingrese su usuario
                </div>
              </div>
              <div className="space-y-1 relative">
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="Contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-3 flex items-center"
                  aria-label={
                    showPassword ? "Ocultar contraseña" : "Mostrar contraseña"
                  }
                >
                  {showPassword ? (
                    <EyeSlashIcon className="h-5 w-5 text-gray-700" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-700" />
                  )}
                </button>
              </div>
              {errorMessage && (
                <div className="text-xs text-center text-red-500">
                  {errorMessage}
                </div>
              )}
              <div className="flex gap-4 pt-2">
                <Button variant="primary" className="w-full" type="submit">
                  INGRESAR
                </Button>
                <Button
                  variant="secondary"
                  className="w-full"
                  type="button"
                  onClick={handleGuestLogin}
                >
                  INVITADO
                </Button>
              </div>
            </form>
            <div className="flex items-stretch rounded-lg shadow-md overflow-hidden">
              <div className="flex items-center justify-center bg-blue-500 w-14 p-4">
                <Info className="text-white w-6 h-6" />
              </div>
              <div className="flex items-center bg-blue-100 p-2">
                <p className="text-blue-500 text-xs">
                  Ingresa tus datos para iniciar sesión. Si necesitas asistencia
                  como invitado, elige la opción de ingreso sin cuenta.
                </p>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default Login;
