import React from "react";
import { useTheme } from "../ThemeContext";

export const Card = ({ children, className = "", ...props }) => {
  const { isDarkMode } = useTheme();

  return (
    <div
      className={`border ${
        isDarkMode ? "border-green-500" : "border-green-500"
      } rounded-lg shadow-md p-10 ${
        isDarkMode ? "bg-transparent" : "bg-transparent"
      } ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

export const CardHeader = ({ children, className = "", ...props }) => {
  const { isDarkMode } = useTheme();

  return (
    <div
      className={`lg:px-6 lg:py-6 ${
        isDarkMode ? "text-gray-400" : "text-black"
      } ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

export const CardTitle = ({ children, className = "", ...props }) => {
  const { isDarkMode } = useTheme();

  return (
    <h3
      className={`text-4xl font-semibold text-center pb-4 ${
        isDarkMode ? "text-white" : "text-black"
      } ${className}`}
      {...props}
    >
      {children}
    </h3>
  );
};

export const CardContent = ({ children, className = "", ...props }) => {
  const { isDarkMode } = useTheme();

  return (
    <div
      className={`px-0 py-4 ${
        isDarkMode ? "text-gray-400" : "text-black"
      } ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};
